/* Import the Fira Sans Google Font */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

$font-stack: 'Fira Sans', sans-serif;
$primary-color: #a9c4c0;
$secondary-color: #3f4f4c;
$background: #FFFFFF;

.App {
  background-color: $background;
  margin: 0;
  padding: 0;
  font-family: $font-stack;
}