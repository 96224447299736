$font-stack: 'Fira Sans', sans-serif;
$primary-color: #a9c4c0;
$secondary-color: #3f4f4c;

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contents {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    border-style: solid;
    box-shadow: 7px 7px 2px 1px;
    width: 65vw;
    padding: 25px;
    margin-top: 25px;

    .header_image {
        max-height: 60vmin;
        margin-right: 5px;
        border-radius: 5px;
    }

    .horizontal_image {
        max-height: 40vmin;
    }
}

.description {
    padding: 5%;
    line-height: 10px;

    h1 {
        font-size: 3rem;
        line-height: 50px; 
    }

    p {
        font-size: 1rem;
        line-height: 30px;
    }

    span {
        text-decoration: line-through;
    }
}

a {
    text-decoration: none;
    color: $secondary-color;
    font-weight: bold;
}

a:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1550px) {
    .contents {
        flex-flow: row wrap;
    }
}