/* Import the Fira Sans Google Font */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

$font-stack: 'Fira Sans', sans-serif;
$primary-color: #a9c4c0;
$secondary-color: #3f4f4c;

.card-container {
    background-color: $secondary-color;
    width: 15vw;
    border-radius: 5px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .card-main-text {
        h1 {
            font-size: xx-large;
            color: white;
        }
    
        h3 {
            font-style: italic;
            color: white;
        }
    }

    button {
        background-color: transparent;
        border-style: none;
        color: $primary-color;
        padding: 0;

        p {
          font-family: $font-stack;
          font-weight: 500;
        }
    }

    button:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .card-container {
        box-sizing: border-box;
        width: 95%;
        margin: 0;
    }
}