.carousel {
    display: flex;
    flex-flow: row;
    list-style: none;

    li {
        margin: 5px;
    }
}

.li-0 {
    padding-top: 75px;
}

.li-2 {
    padding-top: 100px;
}

.li-3 {
    padding-top: 50px;
}

@media (max-width: 768px) {
    .carousel {
        flex-flow: column;
        width: 100%;
        height: 100%;

        li {
            padding: 0;
            margin: 5px;
        }
    }
}