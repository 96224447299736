.project-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    max-width: 65vw;
    margin-top: 25px;
    padding: 25px;
    border-style: solid;
    box-shadow: 7px 7px 2px 1px;

    .header_image {
        height: 25vh;
    }

    .desktop_header_image {
        height: 30vh;
    }
}

.project-description {
    padding: 5%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1 {
        font-size: 2rem;
        line-height: 50px;
    }

    p {
        font-size: 1rem;
        line-height: 30px;
    }

    span {
        font-weight: bold;
    }

    h4 {
        font-weight: 500;
    }
}

.horizontal {
    flex-flow: row wrap;
}

@media (max-width: 768px) {
    .project-container {
        flex-flow: row wrap;

        .desktop_header_image {
            height: 35vw;
        }
    }

    .project-description {
        max-width: 100%;
    }
}