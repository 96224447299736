@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap);
/* Import the Fira Sans Google Font */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282a36;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{background-color:#fff;margin:0;padding:0;font-family:"Fira Sans",sans-serif}

.Toolbar{display:flex;flex-flow:column;justify-content:center;align-items:center;min-height:10vh;width:100vw;max-width:100%}.header{display:flex;align-items:center;justify-content:space-between;width:95%;padding-top:25px;padding-bottom:25px}.title{display:flex;width:230px;justify-content:center}.buttonWithText{border:none;background-color:transparent;font-family:"Fira Sans",sans-serif;font-size:1rem}.buttonWithText:hover{text-decoration:underline;cursor:pointer}.desktopMenu{display:flex;justify-content:space-evenly;width:500px}.mobileMenuIcon{margin-right:45px}.mobileMenu{border-top:2px solid black;width:100%;display:flex;flex-direction:column;justify-content:center;margin-bottom:20px}.mobileLink{font-size:2rem;font-family:"Fira Sans",sans-serif;font-weight:500;background-color:transparent;border:none;border-bottom:2px solid black;padding:5px;color:black}.mobileLink:hover{text-decoration:underline;cursor:pointer}@media (prefers-reduced-motion: no-preference){.blink{animation:blink infinite 1.5s linear;-webkit-animation:blink infinite 1.5s linear}}@-webkit-keyframes blink{0%,100%{opacity:0}50%{opacity:1}}@keyframes blink{0%,100%{opacity:0}50%{opacity:1}}

.carousel{display:flex;flex-flow:row;list-style:none}.carousel li{margin:5px}.li-0{padding-top:75px}.li-2{padding-top:100px}.li-3{padding-top:50px}@media (max-width: 768px){.carousel{flex-flow:column;width:100%;height:100%}.carousel li{padding:0;margin:5px}}

.card-container{background-color:#3f4f4c;width:15vw;border-radius:5px;padding:20px;color:white;display:flex;flex-direction:column;justify-content:space-between;align-items:flex-start}.card-container .card-main-text h1{font-size:xx-large;color:white}.card-container .card-main-text h3{font-style:italic;color:white}.card-container button{background-color:transparent;border-style:none;color:#a9c4c0;padding:0}.card-container button p{font-family:"Fira Sans",sans-serif;font-weight:500}.card-container button:hover{text-decoration:underline;cursor:pointer}@media (max-width: 768px){.card-container{box-sizing:border-box;width:95%;margin:0}}

.home-container{flex-flow:column;font-family:"Fira Sans",sans-serif}.intro-container{display:flex;flex-flow:row wrap;justify-content:space-evenly;align-items:center;padding:50px}.intro-container img{margin-top:10px;max-height:40vmin}.intro-container .disclaimer{text-align:center;font-weight:500;font-size:large}.desktop-intro{align-items:center}.desktop-intro h1{font-size:8.75vmin;font-weight:500;line-height:20px}.desktop-intro h2{font-size:3vmin;font-weight:400;line-height:20px}.projects-container{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:50px;background-color:#a9c4c0}.projects-container h1{color:#3f4f4c;font-size:50px}@media (max-width: 768px){.intro-container h1{font-size:3rem;line-height:normal;margin:0}.intro-container h2{font-size:1.5rem;line-height:normal;margin:0}}

.container{display:flex;flex-direction:column;justify-content:center;align-items:center}.contents{display:flex;flex-flow:row;justify-content:center;align-items:center;border-style:solid;box-shadow:7px 7px 2px 1px;width:65vw;padding:25px;margin-top:25px}.contents .header_image{max-height:60vmin;margin-right:5px;border-radius:5px}.contents .horizontal_image{max-height:40vmin}.description{padding:5%;line-height:10px}.description h1{font-size:3rem;line-height:50px}.description p{font-size:1rem;line-height:30px}.description span{text-decoration:line-through}a{text-decoration:none;color:#3f4f4c;font-weight:bold}a:hover{text-decoration:underline;cursor:pointer}@media (max-width: 1550px){.contents{flex-flow:row wrap}}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    width: 50vw;
    padding: 10px;
}

.contact-title {
    width: 100%;
}

.contact-title h1 {
    font-size: 45px;
}

.contact-description p {
    font-size: 18px;
    line-height: 35px;
}

.contact-form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.contact-form h3 {
    width: 100%;
}

.contact-form input[type=text] {
    height: 40px;
}

.name-form {
    display: flex;
    flex-flow: column;
    width: 49%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.email-form {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.subject-form {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.message-form {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 24px;
}

.message-form input[type=text] {
    height: 100px;
}

.submit {
    width: 100px;
    height: 50px;
    background-color: transparent;
    border-radius: 25px;
    font-weight: bold;
}

.submit:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}
.project-container{display:flex;flex-flow:row;justify-content:center;align-items:center;max-width:65vw;margin-top:25px;padding:25px;border-style:solid;box-shadow:7px 7px 2px 1px}.project-container .header_image{height:25vh}.project-container .desktop_header_image{height:30vh}.project-description{padding:5%;max-width:50%;display:flex;flex-direction:column;align-items:flex-start}.project-description h1{font-size:2rem;line-height:50px}.project-description p{font-size:1rem;line-height:30px}.project-description span{font-weight:bold}.project-description h4{font-weight:500}.horizontal{flex-flow:row wrap}@media (max-width: 768px){.project-container{flex-flow:row wrap}.project-container .desktop_header_image{height:35vw}.project-description{max-width:100%}}

.footer {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 300;
    color: #6272a4;
    text-align: center;
    margin-top: 10px;
    bottom: 1px;
    font-size: 75%;
}
